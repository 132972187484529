import { IJob } from '@medely/types';
import { Box, Heading, HStack, Icon, Label, Text } from '@medely/ui-kit';
import React from 'react';
import { ExtendedRateInfo } from '../../ExtendedRateInfo';
import { JobDetailsListItem } from '../../JobDetailsListItem';
import { getOTRateLabel } from '@medely/base';
import { EstimatedPayDisclaimer } from '../../EstimatedPayDisclaimer';
import { Divider } from '@mp/components/divider/Divider';
import { HelpCenterLink } from '@mp/components/helpCenterLink';
import { gateNames } from '@mp/constants';
import { useGate } from '@mp/hooks';
import { centsToCurrency, jobBonusCents } from '@mp/utils';

type JobCompensationProps = {
  job: IJob;
  smallIcon?: boolean;
};

export const JobCompensation = ({ job, smallIcon }: JobCompensationProps) => {
  const bonusCents = jobBonusCents(job);
  const withBonus = bonusCents > 0;
  const { value: showXRInfo } = useGate(gateNames.xrTransparency);

  if (!job.job_billing_sums?.payout_total_amount_cents) {
    return <></>;
  }

  return (
    <Box testId="job-compensation">
      <Heading size="s" color="text.primary" testId="job-compensation-title">
        Compensation
      </Heading>
      <Box mt={2}>
        <Box mb={1.5}>
          <Label size="m" testId="job-compensation-box-estimated-pay">
            Estimated Pay
          </Label>
        </Box>
        <HStack mb={1} justifyContent="space-between">
          <Text size="m" color="text.secondary" testId="job-compensation-box-shift-total">
            Shift Total ({job.job_billing_sums?.total_hours} Hours)
          </Text>
          <Text
            size="m"
            color="text.secondary"
            textAlign="right"
            testId="job-compensation-box-shift-total-amount"
          >
            {centsToCurrency(job.job_billing_sums?.payout_total_amount_cents)}
          </Text>
        </HStack>
        {withBonus && (
          <HStack mb={1.5} justifyContent="space-between">
            <Box testId="job-compensation-box-bonus">
              <Text size="m" color="text.secondary" testId="job-compensation-box-bonus-title">
                Boost
              </Text>
            </Box>
            <Text
              size="m"
              color="text.secondary"
              textAlign="right"
              testId="job-compensation-box-bonus-amount"
            >
              {centsToCurrency(bonusCents)}
            </Text>
          </HStack>
        )}
      </Box>
      <Box py={0.5} pb={2}>
        <Divider />
      </Box>
      <HStack mb={1} justifyContent="space-between">
        <Box>
          {job.is_w2 ? (
            <Label size="m" color="text.primary" testId="job-compensation-box-gross-pay-title">
              Gross Pay
            </Label>
          ) : (
            <Box>
              <Label size="m" color="text.primary" testId="job-compensation-box-total-pay-title">
                Total Estimated Pay
              </Label>
            </Box>
          )}
        </Box>
        <Label
          size="m"
          color="text.primary"
          textAlign="right"
          testId="job-compensation-box-total-pay-amount"
        >
          {centsToCurrency(job.job_billing_sums?.payout_total_amount_cents + bonusCents)}
        </Label>
      </HStack>
      {job.is_w2 && (
        <Box bgColor="state.info.backing" borderRadius="10px" p={2} my={2}>
          <Text size="l" color="state.teal.primary">
            W-2 Shift
          </Text>
          <Box my={1}>
            <Text size="m" color="state.teal.primary">
              Payments for W-2 shifts are processed once a week with taxes withheld.
            </Text>
          </Box>
          <HelpCenterLink configKey="w2Shifts" text="Learn more" />
        </Box>
      )}
      {showXRInfo && (
        <Box pb={2}>
          <JobDetailsListItem
            icon={
              <Icon
                name="light-calendar-clock"
                iconSize={smallIcon ? '14px' : '20px'}
                color="text.primary"
                variant="custom"
              />
            }
            title={`${getOTRateLabel({ isW2: job.is_w2, abbreviate: false })}s`}
            small
          >
            <ExtendedRateInfo job={job} />
          </JobDetailsListItem>
        </Box>
      )}
      {!!job.estimated_unpaid_break_time_minutes && (
        <Box pb={2}>
          <JobDetailsListItem
            icon={
              <Icon
                name="light-burger-soda"
                iconSize={smallIcon ? '14px' : '20px'}
                color="text.primary"
                variant="custom"
              />
            }
            title="Unpaid Meal Break"
            small
          >
            <Text size="m" color="text.secondary">
              {`This shift has ${job.estimated_unpaid_break_time_minutes} minutes of meal break, which is not included in your total completed hours. `}
              <HelpCenterLink configKey="breaksAndLunches" text="Learn more" />
            </Text>
          </JobDetailsListItem>
        </Box>
      )}
      <Box pb={2}>
        <JobDetailsListItem
          icon={
            <Icon
              name="info-circle"
              iconSize={smallIcon ? '14px' : '20px'}
              color="text.primary"
              variant="custom"
            />
          }
          small
        >
          <EstimatedPayDisclaimer />
        </JobDetailsListItem>
      </Box>
    </Box>
  );
};
