import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useAnalytics } from '@medely/ui-kit';

const DEFAULT_DEBOUNCE_TIME = 500;

export type TrackNextStepCardsViewPayload = {
  cards: {
    position: number;
    card_name: string;
  }[];
};

export type TrackNextStepCardClickPayload = {
  position: number;
  card_name: string;
};

export function useNextStepTracking() {
  const analytics = useAnalytics();

  const trackNextStepCardsView = useCallback(
    debounce((payload: TrackNextStepCardsViewPayload) => {
      analytics.track({
        context: 'Today',
        subcontext: null,
        action: 'Viewed',
        cards: JSON.stringify(payload.cards),
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [analytics],
  );

  const trackNextStepCardClick = useCallback(
    (payload: TrackNextStepCardClickPayload) => {
      analytics.track({
        context: 'Today',
        subcontext: 'Next Steps List Item',
        action: 'Clicked',
        ...payload,
      });
    },
    [analytics],
  );

  return {
    trackNextStepCardsView,
    trackNextStepCardClick,
  };
}

export default useNextStepTracking;
