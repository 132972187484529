export const config = {
  // this value can only be used on mobile app
  proMainPage: process.env.EXPO_PUBLIC_WEB_PRO_CLIENT_URL,
  help: {
    url: 'https://help.medely.com',
    setYourselfUpForSuccess:
      'https://help.medely.com/en/articles/6971077-professional-how-to-set-yourself-up-for-success-at-medely',
    learnHowMedelyWorksAgency: 'https://help-partners.medely.com/en/',
    clockInClockOut: 'https://help.medely.com/en/articles/103312-clocking-in-out',
    cancellationsAndNoShow:
      'https://help.medely.com/en/articles/1871897-professional-cancellation-policy',
    cancellationsAndNoShowAgency:
      'https://help-partners.medely.com/en/articles/9151633-cancellation-policy',
    limitedTimeIncentiveProgram:
      'https://help.medely.com/en/articles/9736000-limited-time-incentive-program',
    learnAboutPayouts: 'https://help.medely.com/en/articles/9736094-learn-about-payouts',
    paymentsForTravelAssignments:
      'https://help.medely.com/en/articles/2712787-payments-for-travel-assignments',
    paymentsFor99Assignments:
      'https://help.medely.com/en/articles/5575666-payments-for-1099-assignments',
    paymentsForPerDiemShifts:
      'https://help.medely.com/en/articles/1214979-payments-for-per-diem-shifts',
    stripeRepresentativeTaxInfo:
      'https://help.medely.com/en/articles/4901773-direct-deposit-business',
    formFaq: 'https://help.medely.com/en/articles/6644709-530-form-faq',
    weekly1099ExtendedRate:
      'https://help.medely.com/en/articles/10042833-professional-weekly-1099-extended-rates',
    w2Shifts: 'https://intercom.help/frontline-health/en/articles/8464674-w-2-shifts',
    breaksAndLunches: 'https://help.medely.com/en/articles/596823-professional-breaks-and-lunches',
    covidVaccineOrTest:
      'https://help.medely.com/en/articles/5497289-covid-vaccine-booster-or-test-protocol-when-required-by-state-or-facility',
    communityGuidelines: 'https://help.medely.com/en/articles/4564959-community-guidelines',
    communityGuidelinesAgency:
      'http://help-partners.medely.com/en/articles/9098701-medely-agency-staff-community-guidelines',
    professionalTermsPathname: 'https://medely.com/professional_terms',
    profileStats: 'http://help.medely.com/en/articles/4999342-profile-stats',
    profileStatsAgency: 'http://help-partners.medely.com/en/articles/9092604-profile-stats',
  },
};
