import { TaskCard } from '@medely/ui-kit';
import React from 'react';
import { WhatToExpectModal } from '../whatToExpectModal/WhatToExpectModal';
import useDialog from '@mp/hooks/useDialog';
import { TaskCardLayout } from './TaskCard';

interface GetReadyProps {
  onTrackClick?: () => void;
}

export const GetReady = ({ onTrackClick }: GetReadyProps) => {
  const { open, closeDialog, openDialog } = useDialog();

  const handleClick = () => {
    onTrackClick?.();
    openDialog();
  };

  return (
    <>
      <TaskCardLayout>
        <TaskCard
          iconProps={{ name: 'calendar-check', color: 'primary' }}
          title="Get ready for your shift"
          body="Congratulations on booking a shift with Medely! Learn what to expect from here on out."
          onClick={handleClick}
        />
      </TaskCardLayout>
      <WhatToExpectModal open={open} onClose={closeDialog} />
    </>
  );
};
