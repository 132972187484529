import { Analytics } from '@medely/ui-kit';

export const useSpecialtyTracking = (analytics: Analytics) => {
  return {
    trackPromptedForSpecialtyRequirements: (professionalId?: number) => {
      analytics.track('Job Booking - Specialty Acknowledgement - Viewed', {
        professional_id: professionalId,
        context: 'Job Booking',
        subcontext: 'Specialty Acknowledgement',
        action: 'Viewed',
      });
    },
    trackSpecialtyRequirementsModalInteract: (
      professionalId: number | undefined,
      specialtiesToAdd: number[],
      specialtiesToRemove?: number[],
    ) => {
      analytics.track('Specialty Acknowledgement - Confirm Button - Clicked', {
        professional_id: professionalId,
        specialties_to_add: specialtiesToAdd,
        specialties_to_remove: specialtiesToRemove,
        context: 'Specialty Acknowledgement',
        subcontext: 'Confirm Button',
        action: 'Clicked',
      });
    },
    trackSpecialtiesAndPositionsViewed: (deepLinkParams: {
      os: string | null;
      utm_source?: string | null;
      utm_campaign?: string | null;
      utm_term?: string | null;
    }) => {
      analytics.track('Positions and Specialties - Viewed', {
        context: 'Positions and Specialties',
        action: 'Viewed',
        ...deepLinkParams,
      });
    },
  };
};
