import React, { useCallback } from 'react';
import { Box, InfoBadge, InfoBadgeColor, TaskCard } from '@medely/ui-kit';
import { useProNavigate } from '../../contexts/NavigationContext';
import { TaskCardLayout } from './TaskCard';

type ExpiringConditionsProps = {
  title: string;
  severity: InfoBadgeColor;
  onPress?: () => void;
  onTrackClick?: () => void;
};

export const ExpiringConditions = ({
  title,
  severity,
  onPress,
  onTrackClick,
}: ExpiringConditionsProps) => {
  const { navigate } = useProNavigate();

  const handleClick = useCallback(() => {
    onTrackClick?.();
    onPress?.();
    navigate('MissingAndExpiringConditions');
  }, [navigate, onPress, onTrackClick]);

  return (
    <TaskCardLayout>
      <TaskCard
        iconProps={{ name: 'exclamation-triangle', color: severity }}
        title={title}
        body={
          <Box alignSelf="flex-start">
            <InfoBadge label="Needs Attention" color={severity} />
          </Box>
        }
        onClick={handleClick}
      />
    </TaskCardLayout>
  );
};

export default ExpiringConditions;
