import React, { ReactElement } from 'react';
import { CARD_NAME_MAPPING as cardNameMapping } from '@mp';

const MAX_NUM_OF_NEXT_STEPS_CARDS = 3;

export type RenderCardMap<T> = {
  [Key in keyof T]?: (data: T[Key]) => ReactElement | ReactElement[] | null;
};

export type TrackNextStepCardClick = (data: { position: number; card_name: string }) => void;

export type Platform = 'web' | 'mobile';

export interface NextStepsHelpersConfig {
  platform: Platform;
  isAgencyPro?: boolean;
}

// Common helper function to compare arrays
export const arrayEquals = (a: string[], b: string[]) =>
  a.length === b.length && a.every((v, i) => v === b[i]);

// Returns the limited steps, regardless of the platform
export const getLimitedNextSteps = <T extends Record<string, unknown>>(
  keys: (keyof T)[],
  fullTodos: T,
  renderCard: RenderCardMap<T>,
) => {
  const rawKeys: (keyof T)[] = [];
  const rawItems = keys
    .filter((key) => key in fullTodos)
    .map((key) => {
      const element = renderCard[key]?.(fullTodos[key]);
      if (element) {
        rawKeys.push(key);
      }
      return element;
    })
    .filter(Boolean) as ReactElement[];

  return {
    limitedKeys: rawKeys.slice(0, MAX_NUM_OF_NEXT_STEPS_CARDS),
    limitedItems: rawItems.slice(0, MAX_NUM_OF_NEXT_STEPS_CARDS),
  };
};

// Extracted web-specific implementation.
const trackCardInteractionsWeb = <T extends string>(
  items: ReactElement[],
  keys: T[],
  trackNextStepCardClick: TrackNextStepCardClick,
): ReactElement[] => {
  return items.flatMap((element, index) => {
    const actualKey = keys[index];
    const cardName = cardNameMapping[actualKey] || actualKey || `card-${index + 1}`;
    const arrayOfElements = Array.isArray(element) ? element : [element];

    return arrayOfElements.map((child, subIndex) => {
      if (!React.isValidElement(child)) {
        return child;
      }
      const uniqueKey = `${cardName}-${index}-${subIndex}`;
      const childProps = child.props as Record<string, any>;
      const eventProp = 'onPress' in childProps ? 'onPress' : 'onClick';
      const existingHandler = childProps[eventProp];

      const handleClick = (evt: React.MouseEvent | React.TouchEvent) => {
        trackNextStepCardClick({ position: index + 1, card_name: cardName });
        if (existingHandler) {
          existingHandler(evt);
        }
      };

      return existingHandler ? (
        React.cloneElement(child, { key: uniqueKey, [eventProp]: handleClick })
      ) : (
        <div key={uniqueKey} onClick={handleClick}>
          {child}
        </div>
      );
    });
  });
};

// Extracted mobile-specific implementation.
const trackCardInteractionsMobile = <T extends string>(
  items: ReactElement[],
  keys: T[],
  trackNextStepCardClick: TrackNextStepCardClick,
  isAgencyPro?: boolean,
): ReactElement[] => {
  const filteredKeys = isAgencyPro
    ? keys.filter((key) => key !== 'finished_jobs_count' && key !== 'set_yourself_up')
    : keys;
  const cardsWithKeys = filteredKeys
    .map((key, index) => ({ key, element: items[index] }))
    .filter(({ element }) => React.isValidElement(element));
  const limitedCards = cardsWithKeys.slice(0, MAX_NUM_OF_NEXT_STEPS_CARDS);
  return limitedCards.map(({ key, element }, index) =>
    React.cloneElement(element, {
      key,
      onTrackClick: () => trackNextStepCardClick({ position: index + 1, card_name: key }),
    }),
  );
};

// Unified implementation that delegates to platform-specific functions.
export const trackCardInteractions = <T extends string>(
  items: ReactElement[],
  keys: T[],
  trackNextStepCardClick: TrackNextStepCardClick,
  config: NextStepsHelpersConfig,
): ReactElement[] => {
  const methods = {
    web: () => trackCardInteractionsWeb(items, keys, trackNextStepCardClick),
    mobile: () =>
      trackCardInteractionsMobile(items, keys, trackNextStepCardClick, config.isAgencyPro),
  };
  return methods[config.platform]?.() || [];
};

// Exclusive function for mobile to track card views.
export const trackViewedCards = (
  isReady: boolean,
  wrappedCards: ReactElement[],
  trackNextStepCardsView: (data: { cards: { position: number; card_name: string }[] }) => void,
  lastTrackedKeysRef: React.MutableRefObject<string[]>,
): void => {
  if (!isReady || wrappedCards.length === 0) {
    return;
  }
  const currentKeys = wrappedCards.map((c) => c.key as string);
  if (JSON.stringify(lastTrackedKeysRef.current) !== JSON.stringify(currentKeys)) {
    const cards = wrappedCards.map((card, index) => ({
      position: index + 1,
      card_name: card.key as string,
    }));
    trackNextStepCardsView({ cards });
    lastTrackedKeysRef.current = currentKeys;
  }
};

// Exclusive function for mobile to limit the number of displayed cards.
export function getMaximumAmountOfCardsToBeDisplayed<T>(
  nextSteps: { key: string; element: T }[],
): { key: string; element: T }[] {
  return nextSteps.slice(0, MAX_NUM_OF_NEXT_STEPS_CARDS);
}
