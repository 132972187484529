export const CARD_NAME_MAPPING: Record<string, string> = {
  campaigns_status: 'campaigns_status',
  disputes_waiting_on_professional: 'disputes_waiting_on_professional',
  active_jobs_requesting_to_work_facility_ids: 'active_jobs_requesting_to_work_facility_ids',
  enable_location_services: 'enable_location_services',
  unsigned_addendum_assignment_ids: 'unsigned_addendum_assignment_ids',
  extension_offers: 'extension_offers',
  assignment_offers: 'assignment_offers',
  days_until_debit_card_expires: 'days_until_debit_card_expires',
  has_position_with_completed_onboarding: 'has_position_with_completed_onboarding',
  assignments_missing_conditions: 'assignments_missing_conditions',
  expiring_conditions: 'expiring_conditions',
  available_for_extensions: 'available_for_extensions',
  pending_pro_pool_invite: 'pending_pro_pool_invite',
  pending_pro_pool_onboarding: 'pending_pro_pool_onboarding',
  set_yourself_up: 'set_yourself_up',
  get_ready: 'get_ready',
  missing_w2_per_diem_onboarding: 'missing_w2_per_diem_onboarding',
  missing_premier_credentials: 'missing_premier_credentials',
};
